import React from 'react';

import { HeadlineView } from 'utilities/components/headline/component/view';
import { Img } from 'utilities/components/img/component';

import line from 'assets/images/complaints/line.svg';
import camera from 'assets/images/complaints/camera.svg';
import residence from 'assets/images/complaints/residence.svg';
import calendar from 'assets/images/complaints/calendar.svg';
import expert from 'assets/images/complaints/expert.svg';

import './view.scss';

export const ProcessView = () => (
    <section className="process">
        <HeadlineView text="Proces" />
        <div className="process__container">
            <Img className="process__line process__line--first" src={ line } alt="linia" />
            <Img className="process__line process__line--second" src={ line } alt="linia" />
            <Img className="process__line process__line--third" src={ line } alt="linia" />
            <div className="process__step">
                <Img className="process__icon" src={ camera } alt="aparat" />
                <h4 className="process__headline">Udokumentuj zdjęciami wykrytą wadę</h4>
            </div>
            <div className="process__step">
                <Img className="process__icon" src={ residence } alt="siedziba" />
                <h4 className="process__headline">Zgłoś reklamację do punktu sprzedaży, w którym kupiłeś drzwi</h4>
            </div>
            <div className="process__step">
                <Img className="process__icon" src={ calendar } alt="kalendarz" />
                <h4 className="process__headline">Daj nam do 14 dni na odpowiedź</h4>
                Zazwyczaj wystarcza nam 48&nbsp;godzin
            </div>
            <div className="process__step">
                <Img className="process__icon" src={ expert } alt="specjalista" />
                <h4 className="process__headline">Specjaliści ze sklepu, w którym nabyłeś drzwi, bądź technicy &quot;Barańscy Drzwi&quot; skontaktują się z&nbsp;Tobą w celu usunięcia usterki</h4>
            </div>
        </div>
    </section>
);