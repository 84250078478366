import React from 'react';

import { ButtonView } from 'utilities/components/button/component/view';
import { Img } from 'utilities/components/img/component';

import guaranteeCertificate from 'assets/images/complaints/guarantee-certificate.png';
import complaintForm from 'assets/images/complaints/complaint-form.jpg';
import guaranteeCard from 'assets/docs/karta-gwarancyjna-baranski.pdf';
import complaintCard from 'assets/docs/formularz-reklamacyjny-baranski.pdf';

import './view.scss';

export const GuaranteeCertificateView = () => (
    <section className="guarantee-certificate">
        <div className="guarantee-certificate__container">
            <div>
                <Img className="guarantee-certificate__image" src={ guaranteeCertificate } alt="karta gwarancyjna" />
                <h4 className="guarantee-certificate__headline">Karta gwarancyjna</h4>
                <p className="guarantee-certificate__description">Sprawdź warunki gwarancji</p>
                <ButtonView text="Pobierz" isExternal link={ guaranteeCard } download target="_blank" />
            </div>
            <div>
                <Img className="guarantee-certificate__image" src={ complaintForm } alt="formularz reklamacyjny" />
                <h4 className="guarantee-certificate__headline">Fromularz reklamacyjny</h4>
                <p className="guarantee-certificate__description">Prześlij reklamację</p>
                <ButtonView text="Pobierz" isExternal link={ complaintCard } download target="_blank" />
            </div>
        </div>
    </section>
);