import React from 'react';
import PropTypes from 'prop-types';

import { QuestionsView } from './view';

export const Questions = ({ questions }) => {
    const collapse = (event) => {
        event.target.parentElement.classList.toggle('questions__question--active');
    };

    return (<QuestionsView questions={ questions } collapse={ collapse } />);
};

Questions.propTypes = {
    questions: PropTypes.instanceOf(Array).isRequired
};