import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Navigation } from '../utilities/components/navigation/component';
import { BannerView } from '../utilities/components/banner/component/view';
import { ProcessView } from '../features/complaints/process/component/view';
import { GuaranteeCertificateView } from '../features/complaints/guarantee-certificate/component/view';
import { Questions } from '../utilities/components/questions/component';
import { Footer } from '../utilities/components/footer/component';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import SEO from 'utilities/components/seo';

import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { routes } from 'utilities/routes';

import complaintForm from 'assets/images/complaints/complaint-form.jpg';
import { useSeoTexts } from 'utilities/hooks/useSeoTexts';
import { SeoDecription } from 'features/index/seo-description/view';

const Complaints = () => {
    const data = useStaticQuery(graphql`
        query {
            banners(site_type: { eq: "Reklamacje" }) {
                image
                alt_image
                background_position_x
            }
            allFaq(filter: { is_active: { eq: 1 }, faq_types: { elemMatch: { name: { eq: "Reklamacje" } } } }) {
                nodes {
                    question {
                        pl
                    }
                    answer {
                        pl
                    }
                }
            }
        }
    `);

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };

    const breadcrumbs = [routes.home, routes.reklamacje];
    const { metaTitle, metaDescription, description } = useSeoTexts('Reklamacje');

    return (
        <main className="complaints">
            <SEO
                title={ metaTitle || 'Barański Drzwi - Reklamacje - procedury, dokumenty' }
                description={
                    metaDescription
                    || 'Znalazłeś wadę ukrytą w drzwiach? Zobacz procedurę reklamacji i wypełnij odpowiednie dokumenty tutaj.'
                }
                image={ `https://www.baranskidrzwi.pl${complaintForm}` }
                alt="Reklamacje"
                canonicalLink={ setCanonicalLink() }
            />
            <Navigation />
            <BannerView
                image={ `${process.env.GATSBY_URL}/${data?.banners?.image}` }
                headline="Reklamacje"
                x={ data?.banners?.background_position_x }
            />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <ProcessView />
            <GuaranteeCertificateView />
            <Questions questions={ data?.allFaq?.nodes } />
            <SeoDecription seoDescription={ description } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default Complaints;
