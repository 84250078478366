import React from 'react';
import PropTypes from 'prop-types';

import { HeadlineView } from '../../headline/component/view';

import './view.scss';

export const QuestionsView = ({ questions, collapse }) => (
    <section className="questions">
        <HeadlineView text="FAQ" />
        {
            questions?.map((currentValue, index) => (
                <div className="questions__question" key={ index }>
                    <button
                        className="question__button"
                        type="button"
                        onClick={ (event) => {
                            collapse(event);
                        } }
                    >
                        { currentValue?.question?.pl }
                    </button>
                    {/* eslint-disable-next-line react/no-danger */}
                    <p className="question__answer" dangerouslySetInnerHTML={{ __html: currentValue?.answer?.pl }} />
                </div>
            ))
        }
    </section>
);

QuestionsView.propTypes = {
    questions: PropTypes.instanceOf(Array).isRequired,
    collapse: PropTypes.func.isRequired
};